import React from 'react';
import { graphql } from 'gatsby';

import { Card, Row, Col } from 'antd';
import styled from 'styled-components';

import SEO from '@/components/seo';
import Hero from '@/components/public/page-hero';
import Layout from '@/components/layout/public';
import Content from '@/components/content';

const PostContent = styled.div`
	.markdown {
		font-size: 1.1rem;
		line-height: 1.3rem;
		padding: 20px 20px;
	}
	
	.markdown>:first-child {
		margin-top: 0
	}
	
	.markdown h1,
	.markdown h2,
	.markdown h3,
	.markdown h4,
	.markdown h5,
	.markdown h6 {
		font-weight: 600;
		line-height: 1;
		margin-top: 1.5em;
		margin-bottom: 1rem
	}
	
	.markdown h4,
	.markdown h5,
	.markdown h6 {
		font-weight: bolder
	}
	
	.markdown h5 {
		font-size: .875em
	}
	
	.markdown h6 {
		font-size: .75em
	}
	
	.markdown b,
	.markdown optgroup,
	.markdown strong {
		font-weight: bolder
	}
	
	.markdown a {
		text-decoration: none;
		font-weight: 600;
		color: #000;
		border-bottom: 1px dashed #000;
	}
	
	.markdown a:hover {
		border-bottom: 1px solid solid;
	}
	
	.markdown a:visited {
		color: #000;
	}
	
	.markdown img {
		max-width: 100%
	}
	
	.markdown code {
		padding: 0 .25rem;
		background: #e9ecef;
		border-radius: .25rem;
		font-size: .875em
	}
	
	.markdown pre {
		padding: 1rem;
		background: #f8f9fa;
		border-radius: .25rem;
		overflow-x: auto
	}
	
	.markdown pre code {
		padding: 0;
		background: 0 0
	}
	
	.markdown blockquote {
		margin: 1rem 0;
		padding: .5rem 1rem .5rem .75rem;
		border-inline-start: .25rem solid #e9ecef;
		border-radius: .25rem
	}
	
	.markdown blockquote :first-child {
		margin-top: 0
	}
	
	.markdown blockquote :last-child {
		margin-bottom: 0
	}
	
	.markdown table {
		overflow: auto;
		display: block;
		border-spacing: 0;
		border-collapse: collapse;
		margin-top: 1rem;
		margin-bottom: 1rem
	}
	
	.markdown table tr th,
	.markdown table tr td {
		padding: .5rem 1rem;
		border: 1px solid #e9ecef
	}
	
	.markdown table tr:nth-child(2n) {
		background: #f8f9fa
	}
	
	.markdown hr {
		height: 1px;
		border: none;
		background: #e9ecef
	}
	
	.markdown ul,
	.markdown ol {
		padding-inline-start: 2rem;
		
		li {
			margin-bottom: 10px;
		}
	}
	
	.markdown dl dt {
		font-weight: bolder;
		margin-top: 1rem
	}
	
	.markdown dl dd {
		margin-inline-start: 1rem;
		margin-bottom: 1rem
	}
	
	.markdown .highlight table tr td:nth-child(1) pre {
		margin: 0;
		padding-inline-end: 0
	}
	
	.markdown .highlight table tr td:nth-child(2) pre {
		margin: 0;
		padding-inline-start: 0
	}
	
	.markdown details {
		padding: 1rem;
		border: 1px solid #e9ecef;
		border-radius: .25rem
	}
	
	.markdown details summary {
		line-height: 1;
		padding: 1rem;
		margin: -1rem;
		cursor: pointer
	}
	
	.markdown details[open] summary {
		margin-bottom: 0
	}
	
	.markdown-inner>:first-child {
		margin-top: 0
	}
	
	.markdown-inner>:last-child {
		margin-bottom: 0
	}
	
	.markdown .book-expand {
		margin-top: 1rem;
		margin-bottom: 1rem;
		border: 1px solid #e9ecef;
		border-radius: .25rem;
		overflow: hidden
	}
	
	.markdown .book-expand .book-expand-head {
		background: #f8f9fa;
		padding: .5rem 1rem;
		cursor: pointer
	}
	
	.markdown .book-expand .book-expand-content {
		display: none;
		padding: 1rem
	}
	
	.markdown .book-expand input[type=checkbox]:checked+.book-expand-content {
		display: block
	}
	
	.markdown .book-tabs {
		margin-top: 1rem;
		margin-bottom: 1rem;
		border: 1px solid #e9ecef;
		border-radius: .25rem;
		overflow: hidden;
		display: flex;
		flex-wrap: wrap
	}
	
	.markdown .book-tabs label {
		display: inline-block;
		padding: .5rem 1rem;
		border-bottom: 1px transparent;
		cursor: pointer
	}
	
	.markdown .book-tabs .book-tabs-content {
		order: 999;
		width: 100%;
		border-top: 1px solid #f8f9fa;
		padding: 1rem;
		display: none
	}
	
	.markdown .book-tabs input[type=radio]:checked+label {
		border-bottom: 1px solid #05b
	}
	
	.markdown .book-tabs input[type=radio]:checked+label+.book-tabs-content {
		display: block
	}
	
	.markdown .book-columns {
		margin-left: -1rem;
		margin-right: -1rem
	}
	
	.markdown .book-columns>div {
		margin: 1rem 0;
		min-width: 10rem;
		padding: 0 1rem
	}
	
	.markdown a.book-btn {
		display: inline-block;
		font-size: .875rem;
		color: #05b;
		line-height: 2rem;
		padding: 0 1rem;
		border: 1px solid #05b;
		border-radius: .25rem;
		cursor: pointer
	}
	
	.markdown a.book-btn:hover {
		text-decoration: none
	}
	
	.markdown .book-hint.info {
		border-color: #6bf;
		background-color: rgba(102, 187, 255, .1)
	}
	
	.markdown .book-hint.warning {
		border-color: #fd6;
		background-color: rgba(255, 221, 102, .1)
	}
	
	.markdown .book-hint.danger {
		border-color: #f66;
		background-color: rgba(255, 102, 102, .1)
	}
`;

export default function Template({ data }: any) {
	const { markdownRemark } = data;
	const { frontmatter, html } = markdownRemark;

	return (
		<Layout>
			<SEO title={frontmatter.title}/>

			<Hero title={frontmatter.title} style={{
				marginBottom: 50,
			}}/>

			<Content style={{ marginBottom: 40 }}>
				<Row gutter={[16, 16]}>
					<Col span={4}>&nbsp;</Col>
					<Col span={16}>
						<Card style={{ borderRadius: 0 }}>
							<PostContent>
								<div
									className="markdown"
									dangerouslySetInnerHTML={{ __html: html }}
								/>
							</PostContent>
						</Card>
					</Col>
					<Col span={4}>&nbsp;</Col>
				</Row>
			</Content>
		</Layout>
	);
}
export const pageQuery = graphql`
	query($slug: String!) {
		markdownRemark(frontmatter: { slug: { eq: $slug } }) {
			html
			frontmatter {
				date(formatString: "MMMM DD, YYYY")
				slug
				title
			}
		}
	}
`;
